<template>
  <div>
    <h2
      class="text-center font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-12"
    >
      Reset Kata Sandi
    </h2>
    <b-form id="form" @submit="onSubmit">
      <b-form-group id="input-group-newPass" label-for="new-password">
        <template v-slot:label>
          Kata Sandi Baru
        </template>
        <b-form-input
          id="new-password"
          name="new-password"
          type="password"
          v-model="$v.form.pass.$model"
          placeholder="Silahkan Masukan Kata sandi"
          :state="validateState('pass')"
          aria-describedby="new-password"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.pass.required" id="new-password">Kata sandi wajib diisi</b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="!$v.form.pass.minLength" id="new-password">Minimal 6 karakter</b-form-invalid-feedback>

      </b-form-group>
      <b-form-group id="input-group-confirmPass" label-for="confirm-password">
        <template v-slot:label>
          Konfirmasi Kata Sandi Baru
        </template>
        <b-form-input
          id="confirm-password"
          name="confirm-password"
          type="password"
          v-model="$v.form.confirm_pass.$model"
          placeholder="Silahkan Konfirmasi Kata sandi"
          :state="validateState('confirm_pass')"
          aria-describedby="confirm-password"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.confirm_pass.required" id="confirm-password">Konfirmasi Kata sandi wajib diisi</b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="!$v.form.confirm_pass.sameAsPassword" id="confirm-password">Konfirmasi Kata sandi tidak sesuai</b-form-invalid-feedback>
      </b-form-group>
      <div class="text-center">
        <b-button type="submit" class="button-aktivasi"
          >Reset Kata Sandi</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
// import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ActivationFloat",
  props: ["length"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        pass: "",
        confirm_pass: ""
      }
    };
  },
  validations: {
    form: {
      pass: {
        required, minLength: minLength(6),
      },
      confirm_pass: {
        required, sameAsPassword: sameAs("pass")
      }
    }
  },
  mounted() {
    this.validateToken()
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        pass: null,
        confirm_pass: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit(e) {
      e.preventDefault();

      this.$v.form.$touch();

      if (!this.$v.$invalid) {
        const pass = this.$v.form.pass.$model;
        const confirm_pass = this.$v.form.confirm_pass.$model;
        const token = this.$route.query.token;

        axios.post(`${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/lupa-kata-sandi/ubah-sandi/${token}`, {
            password: pass,
            confirm_password: confirm_pass,
            nonOrmas: false
          }
        )
        .then(response => {
            Swal.fire({
              icon: "success",
              title: "Buat kata sandi berhasil, silahkan login",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Login"
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/login');
              }
            });
          return response
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: error.response.data.meta?.message ? error.response.data.meta.message : "Cek kembali isian anda",
            // text: "Silahkan cek email anda untuk melihat sandi anda",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok"
            // footer: '<a href="">Why do I have this issue?</a>'
          });
        });
      }

      if (this.$v.form.$anyError) {
        return false;
      }
    },
    validateToken() {
      const token = this.$route.query.token;

      this.$store
        .dispatch("validasiTokenLupas", token)
        .then((res) => {
          const data = res.data;

          if (data.meta.valid == false) {
            this.status = 'error'
            this.resp = data

            Swal.fire({
              icon: 'warning',
              title: data.message,
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok"
            });

            this.$router.push('/login');
          }
          
        })
        .catch(error => {
          this.status = 'error'
          this.resp = error
          Swal.fire({
            icon: 'warning',
            title: error.message,
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok"
          });

          this.$router.push('/login');
        });
    }
  }
};
</script>

<style scoped>
.button-aktivasi {
  margin-top: 50px;
  background: #063a69;
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
  border-radius: 10px;
  padding: 16px 60px;
}
</style>
